import React from "react"
import CategoryCard from "components/categoryCard"
import Layout from "components/layout"
import { graphql } from "gatsby"

const category = "pcselection";
const path = "/category/pcselection/";

export default function Category(props) {
  // collect images of pcparts category
  const pages = props.data.site.siteMetadata.pages.filter(page => page.category === category);
  pages.forEach(page => {
    page.image = page.image || "cover/defaultthumb.png"
    const name = page.image.split('.')[0].replace(/-/g,'');
    page.name = name.substring(name.lastIndexOf('/') + 1);
  });
  return (
    <Layout {...props} path={path}>
      <h1>目的別パソコンの選び方の記事一覧</h1>
      {pages.map(page => <CategoryCard {...props} {...page}/>)}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        pages {
          title
          description
          image
          category
          path
          noIndex
        }
      }
    }
    business: file(relativePath: { eq: "cover/pcselection/business.png"}) { ...eyecatchImg },
    daigakusei: file(relativePath: { eq: "cover/pcselection/daigakusei.png"}) { ...eyecatchImg },
    gamingpc: file(relativePath: { eq: "cover/pcselection/gamingpc.png"}) { ...eyecatchImg },
    extention: file(relativePath: { eq: "cover/pcselection/extention.png"}) { ...eyecatchImg },
    programming: file(relativePath: { eq: "cover/pcselection/programming.png"}) { ...eyecatchImg },
    note_shoshinsha: file(relativePath: { eq: "cover/pcselection/note_shoshinsha.png"}) { ...eyecatchImg },
    editvideo: file(relativePath: { eq: "cover/pcselection/editvideo.png"}) { ...eyecatchImg },
    used: file(relativePath: { eq: "cover/others/used.png"}) { ...eyecatchImg },
    usedselect: file(relativePath: { eq: "cover/others/usedselect.png"}) { ...eyecatchImg },
    laptopvsdesktop: file(relativePath: { eq: "cover/pcselection/laptop-vs-desktop.png"}) { ...eyecatchImg },
    electricbill: file(relativePath: { eq: "cover/pcselection/electricbill.png"}) { ...eyecatchImg },
    ultramobile: file(relativePath: { eq: "cover/others/ultramobile.png"}) { ...eyecatchImg },
    defaultthumb: file(relativePath: { eq: "cover/defaultthumb.png"}) { ...eyecatchImg },
  }
`
